export const seoWidgetCarouselsSchema: any = {
  $id: 'seowidgetcarousels',
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        _id: { type: 'string' },
        title: { type: 'string' },
        content: { type: 'string' },
        status: { type: 'boolean' },
        img: { type: ['string', 'null'] },
        altImg: { type: ['string', 'null'] }
      },
      required: ['_id', 'title', 'content']
    }
  },
  required: ['type', 'content']
};
