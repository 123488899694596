import {
  IShowNewsletterCookieModalAction,
  IHideNewsletterCookieModalAction,
  INewsletterCookieModalState,
  ISetCookieNewsletterModalAction,
  IConfirmSubscribeCookieNewsletterModalAction,
  ISetToSeenCookieNewsletterModalAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const isShowNewsletterCookieAction =
  (): IShowNewsletterCookieModalAction => ({
    type: ActionTypes.NEWSLETTER.IS_SHOW_NEWSLETTER_COOKIE_MODAL
  });

export const hideNewsletterCookieAction =
  (): IHideNewsletterCookieModalAction => ({
    type: ActionTypes.NEWSLETTER.NEWSLETTER_HIDE_COOKIE_MODAL
  });

export const confirmSubscribeNewsletterCookieModalAction = (
  payload: INewsletterCookieModalState
): IConfirmSubscribeCookieNewsletterModalAction => ({
  type: ActionTypes.NEWSLETTER.CONFIRM_SUBSCRIBE_NEWSLETTER_COOKIE_MODAL,
  payload: payload
});

export const setCookieNewsletterModalAction = (
  payload: INewsletterCookieModalState
): ISetCookieNewsletterModalAction => ({
  type: ActionTypes.NEWSLETTER.SET_NEWSLETTER_COOKIE_MODAL,
  payload: payload
});

export const setToSeenCookieNewsletterModalAction = (
  userId: string
): ISetToSeenCookieNewsletterModalAction => ({
  type: ActionTypes.NEWSLETTER.SET_TO_SEEN_NEWSLETTER_COOKIE_MODAL,
  payload: userId
});
