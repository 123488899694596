import { IProduct } from '../product/types';

export interface ISection {
  type: string;
  content: any;
}

export interface ISimpleCarousel extends ISection {
  content: ISimpleCarouselContent;
}

export enum PriceDisplayTypes {
  standard = 'standard',
  eBook = 'eBook'
}

export interface IContent {
  _id: string;
  title: string;
  startAt: string | null;
  endAt: string | null;
  isTopList?: boolean;
  viewAllUrl?: string;
}

export interface ISimpleCarouselContent extends IContent {
  type: string;
  priceDisplayType: PriceDisplayTypes;
  products: IProduct[];
}

export interface ITabsCarousel extends ISection {
  content: ITabsCarouselContent;
}

export interface ITabsCarouselContent extends IContent {
  tabs: ITabsCarouselContentTabs[];
  priceDisplayType: PriceDisplayTypes;
  type: string;
}

export interface ITabsCarouselContentTabs {
  name: string;
  viewAllUrl: string;
  products: IProduct[];
}

export interface IProminentCarouselContent extends IContent {
  prominentTitle: string;
  priceDisplayType: PriceDisplayTypes;
  prominentSubtitle: string;
  prominentDescription: string;
  prominentImg: string;
  prominentProduct: IProduct | null;
  products: IProduct[];
}

export interface ITopListsContent {
  tabs: ITabsCarouselContentTabs[];
}

export interface ITopListsList {
  orderNumber: number;
  product: IProduct;
}

export interface IFlatCarouselContent extends IContent {
  products: IProduct[];
  priceDisplayType: PriceDisplayTypes;
}

export interface ITwinBannersContent extends IContent {
  banner1: ITwinBanner;
  banner2: ITwinBanner;
}

export interface ITwinBanner {
  img: string;
  link: string;
  _id: string;
}

export interface IThreePartCarouselContent extends IContent {
  priceDisplayType: PriceDisplayTypes;
  slide1: IThreePartCarouselItem;
  slide2: IThreePartCarouselItem;
  slide3: IThreePartCarouselItem;
}

export interface IThreePartCarouselItem {
  img: string;
  description: string;
  product: IProduct;
  prominentText: string;
  signature: string;
}

export interface IWidePromoBannersContent extends IContent {
  _id: string;
  buttonLabel: string;
  description: string;
  img: string;
  mobileImg: string;
  title: string;
  url: string;
  descriptionColor: string;
  titleColor: string;
}

export interface IThreeCollectionCarouselsContent extends IContent {
  priceDisplayType: PriceDisplayTypes;
  group1: IThreeCollectionCarouselsGroup;
  group2: IThreeCollectionCarouselsGroup;
  group3: IThreeCollectionCarouselsGroup;
}

export interface IThreeCollectionCarouselsGroup {
  groupTitle: string;
  image: string;
  products: IProduct[];
  groupUrl?: string | null;
}

export interface IInfoIconsContent extends IContent {
  items: IInfoIconsItem[];
}

export interface IInfoIconsItem {
  boldText: string;
  icon: string;
  regularText: string;
  url?: null | string;
}

export interface ILogoWidgetCarousel {
  _id: string;
  title: string;
  status: boolean;
  items: ILogoWidgetCarouselItem[];
}

export interface ILogoWidgetCarouselItem {
  url: string;
  img: string;
  altImg: string;
}
export interface ISeoWidgetCarousel {
  _id: string;
  title: string;
  content: string;
  status: boolean;
  img?: string;
  altImg?: string;
}
