import Button from '../button';
import PositiveIconImage from '../../assets/images/positive-icon.png';
import React, { FC, useRef, useState } from 'react';
import './scss/style.scss';
import { AiOutlineExpand } from 'react-icons/ai';
import { BiCollapse } from 'react-icons/bi';
import { useMediaQuery } from 'react-responsive';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

interface IProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (open: boolean) => void;
  classes?: string;
}

const PositiveChatBoot: FC<IProps> = ({
  classes,
  setIsDialogOpen,
  isDialogOpen
}: IProps) => {
  const [isDialogExpanded, setIsDialogExpanded] = useState(false);
  const isTablet = useMediaQuery({ query: '(max-width: 850px)' });
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, setIsDialogOpen, false);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const toggleDialogSize = () => {
    setIsDialogExpanded(!isDialogExpanded);
  };

  return (
    <div ref={ref} className={`${classes}`}>
      {isDialogOpen && (
        <div
          className={`dialog-box position-fixed ${
            isDialogExpanded ? 'dialog-expanded' : 'dialog-collapsed'
          }`}
        >
          <div className="dialog-header d-flex background-delfi p-3 ">
            <div
              className="d-flex align-items-center position-absolute header-icons-wrapper"
              onClick={toggleDialogSize}
            >
              {!isTablet ? (
                isDialogExpanded ? (
                  <BiCollapse
                    className="color-white cursor-pointer"
                    size={'1.1rem'}
                    onClick={toggleDialogSize}
                  />
                ) : (
                  <AiOutlineExpand
                    className="color-white cursor-pointer"
                    size={'1.1rem'}
                    onClick={toggleDialogSize}
                  />
                )
              ) : null}
              <button
                type="button"
                className="btn-close close-btn ms-2"
                style={{ filter: 'invert(100%)' }}
                onClick={toggleDialog}
              ></button>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <iframe
                className="position-absolute"
                src="https://salmon-field-08ee3060f.5.azurestaticapps.net/"
                height="440px"
                width="400px"
              />
            </div>
          </div>
        </div>
      )}
      <Button
        classes="positive-logo d-flex justify-content-center align-items-center text-center background-delfi"
        onClick={toggleDialog}
      >
        <img
          src={PositiveIconImage}
          alt="Positive icon"
          className={`${isDialogOpen ? 'icon-large' : ''}`}
          width="50px"
          height="50px"
        />
      </Button>
    </div>
  );
};

export default PositiveChatBoot;
