import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichActionsListPageDataAction,
  setActionsListPageDataAction,
  validateActionsListPageDataAction
} from './actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';
import ActionValidator from '../../../validators/action';
import { IProduct } from '../../../entities/product/types';
import ProductFactory from '../../../factories/product';
import WishListProductValidator from '../../../validators/product/WishListProductValidator';

export const fetchActionsListPageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.ACTIONS_LIST_PAGE.FETCH_ACTIONS_LIST_PAGE_DATA:
        const storeSearchParams = { ...store.getState().searchParams.items };

        if (storeSearchParams.page > 1) {
          storeSearchParams.skip =
            Number(storeSearchParams.limit) * Number(storeSearchParams.page) -
            Number(storeSearchParams.limit);
        }

        if (storeSearchParams.page === 1) {
          storeSearchParams.skip = 0;
        }

        storeSearchParams.param = action.payload;

        next(setLoader(true, MiddlewareEntities.ACTIONS_LIST));
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_ACTIONS_LIST_PAGE,
            storeSearchParams,
            null,
            MiddlewareEntities.ACTIONS_LIST,
            {}
          )
        );
        break;
      case `${MiddlewareEntities.ACTIONS_LIST} ${ActionTypes.API_SUCCESS}`:
        next(validateActionsListPageDataAction(action.payload.data));
        next(setLoader(false, MiddlewareEntities.ACTIONS_LIST));
        break;
      case `${MiddlewareEntities.ACTIONS_LIST} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.ACTIONS_LIST));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.ACTIONS_LIST,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );

        break;
    }
  };

export const validateActionsListPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(
        ActionTypes.ACTIONS_LIST_PAGE.VALIDATE_ACTIONS_LIST_PAGE_DATA
      )
    ) {
      const actions = action.payload.actions.filter((action: any) => {
        return ActionValidator.validate(action);
      });

      const products = action.payload.products
        .map((product: IProduct) => {
          if (WishListProductValidator.validate(product)) {
            return ProductFactory.create(product);
          }
        })
        .filter(
          (product: IProduct) => product !== null && product !== undefined
        );

      next(
        enrichActionsListPageDataAction({
          actions,
          products,
          totalRecords: action.payload.recordsTotal
        })
      );
    }
  };

export const enrichActionsListPageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type ===
      ActionTypes.ACTIONS_LIST_PAGE.ENRICH_ACTIONS_LIST_PAGE_DATA
    ) {
      next(setActionsListPageDataAction(action.payload));
    }
  };

export default [
  fetchActionsListPageDataMiddleware,
  validateActionsListPageDataMiddleware,
  enrichActionsListPageDataMiddleware
];
