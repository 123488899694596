export const SectionTypes = {
  INFO_ICONS: 'infoiconcarousels',
  TAB_CAROUSELS: 'tabcarousels',
  SIMPLE_CAROUSELS: 'simplecarousels',
  PROMINENT_CAROUSELS: 'prominentcarousels',
  TOP_LISTS: 'toplists',
  FLAT_CAROUSELS: 'flatcarousels',
  FLAT_SQUARE_CAROUSELS: 'flatsquarecarousels',
  TWIN_BANNERS: 'twinbanners',
  THREE_PART_CAROUSELS: 'threepartcarousels',
  WIDE_PROMO_BANNERS: 'widepromobanners',
  LAST_VIEWED: 'lastviewed',
  THREE_COLLECTION_CAROUSELS: 'threecollectionscarousels',
  NEWS_CORNER: 'newscorner',
  KIDS_BOOK_PG_RATING_SECTION: 'kidsBookPgRatingSection',
  LOGO_WIDGET_CAROUSELS: 'logowidgetcarousels',
  SEO_WIDGET_CAROUSELS: 'seowidgetcarousels'
};
