import ActionTypes from '../../../constants/ActionTypes';
import { IApiError, IApiRequestAction, IApiSuccessAction } from './types';

export const apiRequest = (
  body: null | object,
  method: string,
  url: string,
  params: null | object,
  data: null | object,
  entity: string,
  headers: object,
  metaData?: object
): IApiRequestAction => ({
  type: ActionTypes.API_REQUEST,
  payload: {
    data: body,
    meta: {
      method,
      url,
      params,
      data,
      entity,
      headers,
      metaData
    }
  }
});

export const apiSuccess = (
  response: object,
  entity: string,
  params: any,
  data: any,
  metaData?: any
): IApiSuccessAction => ({
  type: `${entity} ${ActionTypes.API_SUCCESS}`,
  payload: {
    data: response,
    meta: { entity, params, data, metaData }
  }
});

export const apiError = (
  error: any,
  entity: string,
  metaData?: any
): IApiError => ({
  type: `${entity} ${ActionTypes.API_ERROR}`,
  payload: {
    data: error,
    meta: { entity, metaData }
  }
});
