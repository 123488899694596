import { Modal } from 'react-bootstrap';
import { MdOutlineClose } from 'react-icons/md';
import React, { FC } from 'react';
import './scss/style.scss';

interface IProps {
  handleOnClickHide: () => void;
  classes?: string;
}
const HeaderModal: FC<IProps> = ({ handleOnClickHide, classes }: IProps) => {
  return (
    <Modal.Header className="background-zircon border-0">
      <div
        className={`cursor-pointer close-btn-modal-position ${classes}`}
        onClick={handleOnClickHide}
      >
        <MdOutlineClose size="1.5em" />
      </div>
    </Modal.Header>
  );
};

export default HeaderModal;
