export const MiddlewareEntities = {
  ACTIONS: '[ACTIONS]',
  AUTHOR: '[AUTHOR]',
  PREMIUM_ACTIONS: '[PREMIUM_ACTIONS]',
  ACTIONS_LIST: '[ACTIONS_LIST]',
  ADMINISTRATIVE_BAN: '[ADMINISTRATIVE_BAN]',
  ADVANCED_SEARCH: '[ADVANCED_SEARCH]',
  ASK_ABOUT_PRODUCT: '[ASK_ABOUT_PRODUCT]',
  BOOK: '[BOOK]',
  BOOKSTORE: '[BOOKSTORE]',
  BOOKSTORES: '[BOOKSTORES]',
  BOOKTOK: '[BOOKTOK]',
  BOOKMAKER_MAGAZINE: '[BOOKMAKER_MAGAZINE]',
  EDEN_BOOKS_CREATE_ORDER: '[EDEN_BOOKS_CREATE_ORDER]',
  EDEN_BOOKS_USER_FORGOT_PASSWORD: '[EDEN_BOOKS_USER_FORGOT_PASSWORD]',
  EDEN_BOOKS_USER_RESET_PASSWORD: '[EDEN_BOOKS_USER_RESET_PASSWORD]',
  EDEN_BOOKS_ERROR_MESSAGE: '[EDEN_BOOKS_ERROR_MESSAGE]',
  CART: '[CART]',
  CART_FOR_PAYMENT: '[CART_FOR_PAYMENT]',
  CART_CHANGE_QUANTITY: '[CART_CHANGE_QUANTITY]',
  CAREER: '[CAREER]',
  CHECKOUT: '[CHECKOUT]',
  COMPLAINT: '[COMPLAINT]',
  CREATE_COMPLAINT: '[CREATE_COMPLAINT]',
  COMMENT: '[COMMENT]',
  COMMENTS: '[COMMENTS]',
  COUNTRY: '[COUNTRY]',
  CREATE_USER: '[CREATE_USER]',
  CAREER_USER_SUBMIT_DATA: '[CAREER_USER_SUBMIT_DATA]',
  FAQ: '[FAQ]',
  FETCH_CART_ITEMS: '[FETCH_CART_ITEMS]',
  FETCH_WSPAY_REDIRECT_PARAMS: '[FETCH_WSPAY_REDIRECT_PARAMS]',
  FILM_PAGE: '[FILM_PAGE]',
  FOREIGN_BOOK: '[FOREIGN_BOOK]',
  FORGOT_USER_PASSWORD: '[FORGOT_USER_PASSWORD]',
  GAME_PAGE: '[GAME_PAGE]',
  GIFT_PAGE: '[GIFT_PAGE]',
  GENRES: '[GENRES]',
  GENRE_PAGE: '[GENRE_PAGE]',
  PUBLISHER_PAGE: '[PUBLISHER_PAGE]',
  HOMEPAGE: '[HOMEPAGE]',
  HOMEPAGE_TOP_CAROUSEL: '[HOMEPAGE_TOP_CAROUSEL]',
  KIDS_BOOK: '[KIDS_BOOK]',
  LAST_VIEWED: '[LAST_VIEWED]',
  MENU_ITEMS: '[MENU_ITEMS]',
  MUNICIPALITY: '[MUNICIPALITY]',
  MUSIC: '[MUSIC]',
  NEWS: '[NEWS]',
  NEWSLETTER: '[NEWSLETTER]',
  CONFIRM_NEWSLETTER: '[CONFIRM_NEWSLETTER]',
  NEWSLETTER_COOKIE_MODAL: '[NEWSLETTER_COOKIE_MODAL]',
  NEWSLETTER_UNSUBSCRIBE: '[NEWSLETTER_UNSUBSCRIBE]',
  NEWS_OVERVIEW: '[NEWS_OVERVIEW]',
  NEWS_SECTION: '[NEWS_SECTION]',
  NOTIFICATION: '[NOTIFICATION]',
  NOT_FOUND_URL: '[NOT_FOUND_URL]',
  AVAILABLE_NOTIFICATION: '[AVAILABLE_NOTIFICATION]',
  CREATE_ORDER: '[CREATE_ORDER]',
  PRODUCT_PAGE: '[PRODUCT_PAGE]',
  PRODUCT_BY_NAV_ID: '[PRODUCT_BY_NAV_ID]',
  PRODUCTS_LIST_PAGE: '[PRODUCTS_LIST_PAGE]',
  PRODUCTS_LIST_PAGE_PRODUCTS: '[PRODUCTS_LIST_PAGE_PRODUCTS]',
  PROFILE_USER: '[PROFILE_USER]',
  PROFILE_USER_DELFI_PREMIUM: '[PROFILE_USER_DELFI_PREMIUM]',
  PROFILE_USER_UPDATE: '[PROFILE_USER_UPDATE]',
  PROFILE_USER_PASSWORD_CHANGE: '[PROFILE_USER_PASSWORD_CHANGE]',
  DELETE_PROFILE_USER: '[DELETE_PROFILE_USER]',
  PROFILE_ORDER_HISTORY: '[PROFILE_ORDER_HISTORY]',
  PROFILE_ACTIVITY: '[PROFILE_ACTIVITY]',
  REMOVE_ITEMS_FROM_CART: '[REMOVE_ITEMS_FROM_CART]',
  RESET_USER_PASSWORD: '[RESET_USER_PASSWORD]',
  SCHOOL_BOOK: '[SCHOOL_BOOK]',
  STARWAY: '[STARWAY]',
  SEARCH_ITEMS: '[SEARCH_ITEMS]',
  SEARCH_PARAMS: '[SEARCH_PARAMS]',
  SECTION_PAGE: '[SECTION_PAGE]',
  STATIC_PAGE: '[STATIC_PAGE]',
  ORDER_STATUS: '[ORDER_STATUS]',
  TEXT_SLIDERS: '[TEXT_SLIDERS]',
  TOP_LISTS: '[TOP_LISTS]',
  UI: '[UI]',
  USER: '[USER]',
  USER_CONFIRMATION_REGISTRATION_MESSAGE:
    '[USER_CONFIRMATION_REGISTRATION_MESSAGE]',
  WISHLIST: '[WISHLIST]',
  SAVE_TO_WISHLIST: '[SAVE_TO_WISHLIST]',
  GTM: '[GTM]',
  REDIRECT_AUTHOR_BY_OLD_ID: '[REDIRECT_AUTHOR_BY_OLD_ID]',
  REDIRECT_GENRE_BY_OLD_ID: '[REDIRECT_GENRE_BY_OLD_ID]',
  FILTERS_DATA: '[FILTERS_DATA]',
  COOKIE_CONSENT: '[COOKIE_CONSENT]',
  BOARD_GAMES_PAGE: '[BOARD_GAMES_PAGE]',
  MANGE_PAGE: '[MANGE_PAGE]',
  MAGYAR_PAGE: '[MAGYAR_PAGE]',
  RUSSIAN_BOOKS_PAGE: '[RUSSIAN_BOOKS_PAGE]'
};

export default MiddlewareEntities;
