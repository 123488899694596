import { composeValidators, required } from '../validators';
import { Field } from 'react-final-form';
import Error from '../Error';
import React, { FC, ReactElement } from 'react';
import { FieldValidator } from 'final-form';

interface IProps {
  name: string;
  validators: FieldValidator<any>;
  label?: string;
  type?: string;
  placeholder?: string;
  prefix?: ReactElement | JSX.Element | null;
  errorClasses?: string;
}

const FieldInput: FC<IProps> = ({
  name,
  type = 'text',
  validators,
  label,
  prefix,
  placeholder,
  errorClasses
}: IProps) => {
  return (
    <Field name={name} type={type} validate={validators}>
      {({ input, meta }) => (
        <>
          {label && (
            <label
              htmlFor={name}
              className="form-label text-muted jost-medium text-13"
            >
              {label}
            </label>
          )}
          {prefix ? prefix : null}
          <input
            {...input}
            placeholder={placeholder}
            className={`form-control jost-regular ${
              meta.touched && meta.error ? 'is-invalid' : ''
            }`}
          />
          <Field name={name}>
            {({ meta: { active, dirty, error, touched } }) => (
              <Error
                active={active}
                dirty={dirty}
                error={error}
                touched={touched}
                classes={errorClasses}
              />
            )}
          </Field>
        </>
      )}
    </Field>
  );
};

export default FieldInput;
