import ActionTypes from '../../../constants/ActionTypes';
import {
  INewsLetterSubscribeAction,
  INewsLetterSubscribeRequest,
  ISetSubscribeNewsLetterDataAction
} from './types';

export const subscribeNewsLetterAction = (
  newsLetterSubscribeRequest: INewsLetterSubscribeRequest
): INewsLetterSubscribeAction => ({
  type: ActionTypes.NEWSLETTER.SUBSCRIBE_NEWSLETTER,
  payload: {
    newsLetterSubscribeRequest
  }
});

export const setSubscribeNewsLetterDataAction = (
  status: string,
  message: string,
  isForNewsLetterForModal?: boolean
): ISetSubscribeNewsLetterDataAction => ({
  type: ActionTypes.NEWSLETTER.SET_SUBSCRIBE_NEWSLETTER_MESSAGE,
  payload: {
    status,
    message,
    isForNewsLetterForModal
  }
});
