import { IFactory } from '../../types';
import { ISeoWidgetCarousel } from '../../../entities/section/types';
import Config from '../../../config';
import SeoWidgetCarousel from '../../../entities/section/seo-widget-carousels/SeoWidgetCarousel';

const SeoWidgetCarouselsFactory: IFactory = class SeoWidgetCarouselsFactory {
  static create(seoWidgetCarousel: ISeoWidgetCarousel) {
    return new SeoWidgetCarousel(
      seoWidgetCarousel._id,
      seoWidgetCarousel.title,
      seoWidgetCarousel.content,
      seoWidgetCarousel.status,
      seoWidgetCarousel.img
        ? `${Config.api.mediaUrl}${seoWidgetCarousel.img}`
        : '',
      seoWidgetCarousel.altImg
    );
  }
};

export default SeoWidgetCarouselsFactory;
