import { ISeoWidgetCarousel } from '../types';

export default class SeoWidgetCarousel implements ISeoWidgetCarousel {
  _id: string;

  title: string;

  status: boolean;

  content: string;

  img?: string;

  altImg?: string;

  constructor(
    _id: string,
    title: string,
    content: string,
    status: boolean,
    img?: string,
    altImg?: string
  ) {
    this._id = _id;
    this.title = title;
    this.content = content;
    this.img = img;
    this.altImg = altImg;
    this.status = status;
  }
}
