const CookieExpiredByDays = {
  LAST_VIEWED: 30,
  WISHLIST: 30,
  DELFI_ORDER: 10,
  COOKIE_CONSENT: 366,
  NEWSLETTER_COOKIE_FOR_LOGIN_USER: 400,
  NEWSLETTER_COOKIE_FOR_GUEST_USER: 30
};

export default CookieExpiredByDays;
