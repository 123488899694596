import { useRef, useEffect } from 'react';

export const useOutsideClick = (
  ref: any,
  callback: (value: any) => void,
  value: any
) => {
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback(value);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);
};
