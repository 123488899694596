export const CookieNames = {
  MEMBER: 'delfim',
  WISHLIST: 'wishlist',
  CART: 'dlf.ws.order',
  LAST_VIEWED: 'lastviewed',
  COOKIE_CONSENT: 'cookieConsent',
  NEWSLETTER_MODAL: 'newsletterModal'
};

export default CookieNames;
