import ActionTypes from '../../../constants/ActionTypes';
import {
  INewsletterCookieModalState,
  ISetCookieNewsletterModalAction
} from './types';

import { getCookie } from '../../../utilites/cookies';

const initialState: INewsletterCookieModalState = {
  showNewsletterCookieModal: false
};

const newsletterCookieReducer = (
  state = initialState,
  action: ISetCookieNewsletterModalAction
): INewsletterCookieModalState => {
  switch (action.type) {
    case ActionTypes.NEWSLETTER.SET_NEWSLETTER_COOKIE_MODAL:
      return {
        ...state,
        showNewsletterCookieModal: action.payload.showNewsletterCookieModal
      };
  }

  return state;
};

export default newsletterCookieReducer;
