import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import { setSubscribeNewsLetterDataAction } from './actions';
import { ErrorMessages } from '../../../constants/ErrorMessages';
import { ErrorCodes } from '../../../constants/ErrorCodes';
import { confirmSubscribeNewsletterCookieModalAction } from '../newsletter-cookie-modal/actions';

const subscribeNewsLetterMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWSLETTER.SUBSCRIBE_NEWSLETTER:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.NEWSLETTER_SUBSCRIBE,
            null,
            action.payload.newsLetterSubscribeRequest,
            MiddlewareEntities.NEWSLETTER,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.NEWSLETTER));
        break;
      case `${MiddlewareEntities.NEWSLETTER} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.NEWSLETTER));
        next(
          setSubscribeNewsLetterDataAction(
            'success',
            'Uskoro će na vašu email adresu stići link za potvrdu prijave.',
            action.payload.meta.data.isForNewsLetterForModal
          )
        );
        next(
          confirmSubscribeNewsletterCookieModalAction({
            showNewsletterCookieModal: false
          })
        );
        break;
      case `${MiddlewareEntities.NEWSLETTER} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.NEWSLETTER));
        const requestJsonData = action.payload?.data?.config?.data
          ? JSON.parse(action.payload.data.config.data)
          : null;

        let isForNewsLetterForModal = false;

        if (requestJsonData && requestJsonData.isForNewsLetterForModal) {
          isForNewsLetterForModal = true;
        }

        if (
          action.payload.data &&
          action.payload.data.data &&
          action.payload.data.data.code === ErrorCodes.ALREADY_SUBSCRIBED
        ) {
          next(
            confirmSubscribeNewsletterCookieModalAction({
              showNewsletterCookieModal: false
            })
          );
          return next(
            setSubscribeNewsLetterDataAction(
              'error',
              ErrorMessages.ALREADY_SUBSCRIBED,
              isForNewsLetterForModal
            )
          );
        }

        next(
          setSubscribeNewsLetterDataAction(
            'error',
            ErrorMessages.DEFAULT,
            isForNewsLetterForModal
          )
        );
        break;
    }
  };

export default [subscribeNewsLetterMiddleware];
