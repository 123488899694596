import React, { ReactElement } from 'react';
import Header from './header';
import LayoutBody from './LayoutBody';
import { Outlet } from 'react-router-dom';
import Notification from '../components/notification';
import DelfiScrollManager from './DelfiScrollManager';
import { ChatDialogProvider } from '../components/positive-chat-context';

const Layout: React.FC = (): ReactElement => (
  <DelfiScrollManager>
    <LayoutBody>
      <ChatDialogProvider>
        <Header />
        <Notification />
        <Outlet />
      </ChatDialogProvider>
    </LayoutBody>
  </DelfiScrollManager>
);

export default Layout;
