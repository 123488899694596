import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { getJsonFromCookie, setCookie } from '../../../utilites/cookies';
import CookieNames from '../../../constants/CookieNames';
import {
  setCookieNewsletterModalAction,
  setToSeenCookieNewsletterModalAction
} from './actions';
import CookieExpiredByDays from '../../../constants/CookieExpiredByDays';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import { validateActionsListPageDataAction } from '../actions-list/actions';
import { errorAction } from '../error-handling/actions';
import NotificationTypes from '../../../constants/NotificationTypes';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { fetchUserAction } from '../user/actions';
import { createNotification } from '../notification/actions';

const newsLetterCookieModalMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWSLETTER.IS_SHOW_NEWSLETTER_COOKIE_MODAL:
        const cookieValue = getJsonFromCookie(
          CookieNames.NEWSLETTER_MODAL,
          '{}'
        );

        if (cookieValue && Object.keys(cookieValue).length > 0) {
          return next(
            setCookieNewsletterModalAction({
              showNewsletterCookieModal: false
            })
          );
        }

        const user = store.getState().user.member.user;

        if (user && user.username) {
          if (user.isSubscribedToNewsletter || user.hasSeenNewsletterModal) {
            next(
              setCookieNewsletterModalAction({
                showNewsletterCookieModal: false
              })
            );
          } else {
            next(
              setCookieNewsletterModalAction({
                showNewsletterCookieModal: true
              })
            );
          }

          return setCookie(
            CookieNames.NEWSLETTER_MODAL,
            JSON.stringify({
              showNewsletterCookieModal: false
            }),
            CookieExpiredByDays.NEWSLETTER_COOKIE_FOR_LOGIN_USER
          );
        }

        if (!cookieValue || Object.keys(cookieValue).length === 0) {
          setCookie(
            CookieNames.NEWSLETTER_MODAL,
            JSON.stringify({
              showNewsletterCookieModal: false
            }),
            CookieExpiredByDays.NEWSLETTER_COOKIE_FOR_GUEST_USER
          );
          return next(
            setCookieNewsletterModalAction({
              showNewsletterCookieModal: true
            })
          );
        }
        return next(
          setCookieNewsletterModalAction({
            showNewsletterCookieModal: false
          })
        );

      case ActionTypes.NEWSLETTER.NEWSLETTER_HIDE_COOKIE_MODAL:
        const member = store.getState().user.member.user;

        const cookieNewsletterValue = getJsonFromCookie(
          CookieNames.NEWSLETTER_MODAL,
          '{}'
        );

        let daysOfCookieExpired =
          CookieExpiredByDays.NEWSLETTER_COOKIE_FOR_GUEST_USER;

        if (
          cookieNewsletterValue &&
          Object.keys(cookieNewsletterValue).length > 0
        ) {
          daysOfCookieExpired =
            CookieExpiredByDays.NEWSLETTER_COOKIE_FOR_LOGIN_USER;
        }

        if (member && member.username) {
          daysOfCookieExpired =
            CookieExpiredByDays.NEWSLETTER_COOKIE_FOR_LOGIN_USER;
          next(setToSeenCookieNewsletterModalAction(member.id));
        }

        setCookie(
          CookieNames.NEWSLETTER_MODAL,
          JSON.stringify({ showNewsletterCookieModal: false }),
          daysOfCookieExpired
        );

        next(
          setCookieNewsletterModalAction({
            showNewsletterCookieModal: false
          })
        );

        break;
      case ActionTypes.NEWSLETTER.CONFIRM_SUBSCRIBE_NEWSLETTER_COOKIE_MODAL:
        setCookie(
          CookieNames.NEWSLETTER_MODAL,
          JSON.stringify(action.payload),
          CookieExpiredByDays.NEWSLETTER_COOKIE_FOR_LOGIN_USER
        );

        setTimeout(() => {
          next(setCookieNewsletterModalAction(action.payload));
        }, 4000);
        break;
      case ActionTypes.NEWSLETTER.SUBSCRIBE_NEWSLETTER_COOKIE_MODAL:
        setCookie(
          CookieNames.NEWSLETTER_MODAL,
          JSON.stringify(action.payload),
          CookieExpiredByDays.NEWSLETTER_COOKIE_FOR_LOGIN_USER
        );

        next(setCookieNewsletterModalAction(action.payload));

        break;
    }
  };

const setToSeenNewsLetterCookieModalMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWSLETTER.SET_TO_SEEN_NEWSLETTER_COOKIE_MODAL:
        next(
          apiRequest(
            null,
            'PATCH',
            ApiUrls.SET_TO_SEEN_NEWSLETTER_COOKIE_MODAL,
            null,
            { id: store.getState().user.member.user.sub },
            MiddlewareEntities.NEWSLETTER_COOKIE_MODAL,
            {}
          )
        );
        break;
      case `${MiddlewareEntities.NEWSLETTER_COOKIE_MODAL} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.NEWSLETTER_COOKIE_MODAL));
        break;

      case `${MiddlewareEntities.NEWSLETTER_COOKIE_MODAL} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.NEWSLETTER_COOKIE_MODAL));
        break;
    }
  };

export default [
  newsLetterCookieModalMiddleware,
  setToSeenNewsLetterCookieModalMiddleware
];
