import React, { FC } from 'react';
import AppRouter from './routes';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive';
import Snowfall from 'react-snowfall';

const tagManagerArgs = {
  gtmId: 'GTM-K3GRJMR'
};

const TRACKING_ID = 'G-0E9HNBLLPW';

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(TRACKING_ID);

const App: FC = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  return (
    <>
      <div
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          zIndex: '9999',
          pointerEvents: 'none'
        }}
      >
        {isMobile ? (
          <Snowfall speed={[0.5, 1.0]} color="white" snowflakeCount={50} />
        ) : (
          <Snowfall color="white" snowflakeCount={200} />
        )}
      </div>

      <HelmetProvider>
        <AppRouter />
      </HelmetProvider>
    </>
  );
};

export default App;
