import React, { createContext, useContext, useState } from 'react';

const ChatDialogContext = createContext<{
    isDialogOpen: boolean;
    setIsDialogOpen: (open: boolean) => void;
}>({
    isDialogOpen: false,
    setIsDialogOpen: () => {},
});

export const ChatDialogProvider: React.FC = ({ children }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <ChatDialogContext.Provider value={{ isDialogOpen, setIsDialogOpen }}>
            {children}
        </ChatDialogContext.Provider>
    );
};

export const useChatDialog = () => useContext(ChatDialogContext);